<template>
  <div class="m-3 p-2 wrapper wrapper--default">
    <h1 class="text-center">Zoekresultaten</h1>
    <div class="m-2">
      <h2 v-if="ItemPage[0]">Items</h2>
      <div v-for="(item, key) in ItemPage" :key="key">
        <router-link
          :to="{ name: 'ItemPage', params: { id: item.nummer, bu: item.bu } }"
          >{{ item.nummer }}</router-link
        ><br />
        <span v-if="item.chassisnummer"
          >Chassisnummer: {{ item.chassisnummer }}</span
        >
        <span v-if="item.kenteken">Kenteken: {{ item.kenteken }}</span>
        <hr />
      </div>
    </div>
    <div class="m-2">
      <h2 v-if="LevPage[0]">Leveranciers</h2>
      <div v-for="(item, key) in LevPage" :key="key">
        <router-link
          :to="{ name: 'leverancier', params: { id: item.nummer } }"
          >{{ item.nummer }}</router-link
        ><br />
        <small v-if="item.naam">leverancier naam: {{ item.naam }}</small>
        <hr />
      </div>
    </div>
    <div class="m-2">
      <h2 v-if="klantpagina[0]">Debiteuren</h2>
      <div v-for="(item, key) in klantpagina" :key="key">
        <router-link
          :to="{ name: 'klantpagina', params: { id: item.nummer } }"
          >{{ item.nummer }}</router-link
        ><br />
        <small v-if="item.naam">Klant naam: {{ item.naam }}</small>
        <small v-if="item.orderno">Ordernummer: {{ item.orderno }}</small>
        <small v-if="item.factuur">Factuurnummer: {{ item.factuur }}</small>
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["searchdata"],
  data: () => ({
    ItemPage: null,
    klantpagina: null,
    LevPage: null,
  }),

  created() {
    this.ItemPage = null
    this.klantpagina = null
    this.LevPage = null
    if (this.searchdata) {
      this.$store.dispatch("storeSearchData", this.searchdata);
    }
    this.getData(this.$store.state.search.search_query);
  },
  watch: {
    searchdata(newdata) {
      this.$store.dispatch("storeSearchData", newdata);
      this.getData(newdata);
      this.$forceUpdate();
    },
  },
  methods: {
    getData(data) {
      this.ItemPage = data[0];
      this.klantpagina = data[1];
      this.LevPage = data[2];
      this.loading = false;
    },
  },
};
</script>